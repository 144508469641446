import React from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";
import { Link as ScrollLink, Element } from "react-scroll";
import styled from "styled-components";

import { Section, Button, Title, Text, Box } from "../components/Core";
import PageWrapper from "../components/PageWrapper";
import Masonry from "react-masonry-css";
import { device } from "../utils";
import ResponsiveEmbed from "react-responsive-embed";

const MasonryStyled = styled(Masonry)`
display: flex;
margin-left: -30px;
width: auto;

.masonry-grid_column {
  padding-left: 30px;
  background-clip: padding-box;
}
.masonry-grid_column > div {
  margin-bottom: 30px;

  @media only screen and (max-width: 768px) {
    margin-bottom: 15px;
  }
}
`

const bikeRanch = () => {

    const breakpointColumnsObj = {
        default: 2,
        768: 1
    };

    return (
        <React.Fragment>
            <PageWrapper darkTheme>
                <Section hero>
                    <Container>
                        <Row className="justify-content-center text-center">
                            <Col lg="8">
                                <Box>
                                    <Title className="my-4">
                                        Love The Ride
                                        <br />
                                        Off-grid Rampage prep with Alex Volokhov
                                    </Title>
                                    <Text variant="p" css={` max-width: 750px; `} className="mb-3 mx-auto">
                                        After initial chats internally about creating more long-format content to grow Rocky Mountain's YouTube channel and showcase interesting stories highlighting the passionate people at Rocky, I developped an all-new lighthearted and laid-back video series showing the personable side of our athletes and/or staff.
                                    </Text>
                                </Box>
                            </Col>
                        </Row>

                        <div className="mt-lg-5">
                            <Container>
                                <Row>
                                    <Col lg="4" className="mb-4 mb-lg-0">
                                        <Text variant="tag">Brand</Text>
                                        <Title variant="cardLg" className="mt-3">
                                            Rocky Mountain
                                        </Title>
                                    </Col>
                                    <Col lg="4" className="mb-4 mb-lg-0">
                                        <Text variant="tag">Role</Text>
                                        <Title variant="cardLg" className="mt-3">
                                            Videographer, Producer, Editor, Photographer
                                        </Title>
                                    </Col>
                                    <Col lg="4" className="d-flex justify-content-lg-end">
                                        <ScrollLink to="work" spy={true} smooth={true} offset={-50} duration={1000}>
                                            <Button arrowRight>See More</Button>
                                        </ScrollLink>
                                    </Col>
                                </Row>
                            </Container>
                        </div>

                        <Box pt={["4rem", null, null, "2rem"]}>
                            <StaticImage
                                src="../assets/image/work/love-the-ride-header.jpg"
                                alt="Love the Ride Cover"
                                layout="fullWidth" />
                        </Box>
                    </Container>
                </Section>

                <Element name="work">
                    <Section bg="light" css={` padding-bottom: 15%; `} className="px-lg-4 pt-lg-5">
                        <Container fluid>
                            <MasonryStyled
                                breakpointCols={breakpointColumnsObj}
                                columnClassName="masonry-grid_column">

                                <div>
                                    <ResponsiveEmbed
                                        allowFullScreen
                                        src="https://www.youtube.com/embed/L8cFamI9UQI?si=0tj5QpCwrul0Msxp"
                                        ratio={'16:9'} />
                                </div>

                                <div>
                                    <StaticImage src="../assets/image/work/love-the-ride1.jpg" alt="" layout="fullWidth" />
                                </div>

                                <div>
                                    <StaticImage src="../assets/image/work/love-the-ride2.jpg" alt="" layout="fullWidth" />
                                </div>

                                <div>
                                    <StaticImage src="../assets/image/work/love-the-ride3.jpg" alt="" layout="fullWidth" />
                                </div>

                                <div>
                                    <StaticImage src="../assets/image/work/love-the-ride4.jpg" alt="" layout="fullWidth" />
                                </div>

                                <div>
                                    <StaticImage src="../assets/image/work/love-the-ride5.jpg" alt="" layout="fullWidth" />
                                </div>

                                <div>
                                    <StaticImage src="../assets/image/work/love-the-ride6.jpg" alt="" layout="fullWidth" />
                                </div>

                            </MasonryStyled>
                        </Container>
                    </Section>
                </Element>

                <Section bg="dark">
                    <Container>
                        <Row css={` margin-top: -20% !important; `}>
                            <Col xs="12" className="mb-5">
                                <StaticImage src="../assets/image/work/homegrown.jpg" alt="Homegrown Cover" layout="fullWidth" />
                            </Col>
                        </Row>
                        <div className="text-center mt-lg-5">
                            <Text variant="tag" className="mb-1" color="lightShade">
                                Next Project
                            </Text>
                            <Link to="/homegrown">
                                <Button
                                    arrowRight
                                    className="border-0 bg-transparent shadow-none text-capitalize py-3"
                                    css={` font-weight: 700 font-size: 1.5rem; letter-spacing: -1.2px; line-height: 1.375; @media ${device.md} { font-size: 2rem; } `}>
                                    Homegrown with Maïa Schwinghammer
                                </Button>
                            </Link>
                        </div>
                    </Container>
                </Section>
            </PageWrapper>
        </React.Fragment>
    );
};
export default bikeRanch;
